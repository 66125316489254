<template>
  <div class="page_content page_content_warehouse">
    <div class="content_header d-flex align-items-center">
      <span>庫存管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <!-- <div class="d-flex mb-3">
          <a
            href="#"
            class="btn btn_primary px-2"
            @click.prevent="init_warehouse_detail(), init_validate(), edit_section_new = true, edit_mode = true, edit_section_content = 'warehouse'"
          >新增倉庫</a>
        </div> -->
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tab === 'warehouse_list' }"
              @click.prevent="nav_tab = 'warehouse_list', edit_mode = false"
            >倉庫列表</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tab === 'warehouse_detail' }"
              @click.prevent="nav_tab = 'warehouse_detail', edit_mode = false"
            >庫存</a>
          </li>
        </ul>
        <div
          v-if="nav_tab === 'warehouse_list'"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                  width="150px"
                >
                  代號/倉庫名
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  聯絡人
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  公司
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  聯絡電話
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  地址
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  編輯
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="warehouse in warehouse_list"
                :key="warehouse.depotCode"
              >
                <td>
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_warehouse(warehouse.depotCode, false)"
                  >
                    {{ warehouse.depotCode }}/{{ warehouse.depotName }}
                  </a>
                </td>
                <td class="">
                  {{ warehouse.fullname !== '' ? warehouse.fullname : '無' }}
                </td>
                <td class="">
                  {{ warehouse.company !== '' ? warehouse.company : '無' }}
                </td>
                <td class="">
                  {{ warehouse.celnum }}
                </td>
                <td class="">
                  {{ warehouse.address }}
                </td>
                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <a
                      href="#"
                      class="text-danger"
                      @click.prevent="delete_warehouse(warehouse.depotCode)"
                    >
                      <i class="icon-trash" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-show="nav_tab === 'warehouse_list' && edit_mode && edit_section_content === 'warehouse'"
          class="edit_section"
        >
          <div class="section_header">
            <span v-show="edit_section_new">建立倉庫</span>
            <span v-show="!edit_section_new">編輯倉庫</span>
          </div>
          <div class="section_body container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label>倉庫狀態</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="warehouse_status_true"
                      v-model="warehouse_detail.status"
                      class="form-check-input"
                      type="radio"
                      name="倉庫狀態"
                      :value="true"
                    >
                    <label
                      class="form-check-label"
                      for="warehouse_status_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="warehouse_status_false"
                      v-model="warehouse_detail.status"
                      class="form-check-input"
                      type="radio"
                      name="倉庫狀態"
                      :value="false"
                    >
                    <label
                      class="form-check-label"
                      for="warehouse_status_false"
                    >否</label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="warehouse_depotcode">倉庫代碼</label>
                  <input
                    id="warehouse_depotcode"
                    v-model="warehouse_detail.depotCode"
                    v-validate="'required'"
                    type="text"
                    name="倉庫代碼"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.倉庫代碼')}"
                    data-vv-scope="detail"
                    placeholder="倉庫代碼"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="warehouse_depotname">倉庫名稱</label>
                  <input
                    id="warehouse_depotname"
                    v-model="warehouse_detail.depotName"
                    v-validate="'required'"
                    type="text"
                    name="倉庫名稱"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.倉庫名稱')}"
                    data-vv-scope="detail"
                    placeholder="倉庫名稱"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="warehouse_fullname">聯絡人</label>
                  <input
                    id="warehouse_fullname"
                    v-model="warehouse_detail.fullname"
                    v-validate="'required'"
                    type="text"
                    name="聯絡人"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.聯絡人')}"
                    data-vv-scope="detail"
                    placeholder="聯絡人"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="warehouse_company">公司</label>
                  <input
                    id="warehouse_company"
                    v-model="warehouse_detail.company"
                    type="text"
                    name="公司"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.公司')}"
                    data-vv-scope="detail"
                    placeholder="公司"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-xl-4">
                    <div class="form-group d-flex align-items-center">
                      <label for="warehouse_celnum">手機</label>
                      <input
                        id="warehouse_celnum"
                        v-model="warehouse_detail.celnum"
                        v-validate="'required'"
                        type="text"
                        name="手機"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.手機')}"
                        data-vv-scope="detail"
                        placeholder="手機"
                      >
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="form-group d-flex align-items-center">
                      <label for="warehouse_telnum">電話</label>
                      <input
                        id="warehouse_telnum"
                        v-model="warehouse_detail.telnum"
                        v-validate="'required'"
                        type="text"
                        name="電話"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.電話')}"
                        data-vv-scope="detail"
                        placeholder="電話"
                      >
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="form-group d-flex align-items-center">
                      <label for="warehouse_telnumExt">分機</label>
                      <input
                        id="warehouse_telnumExt"
                        v-model="warehouse_detail.telnumExt"
                        type="text"
                        name="分機"
                        class="form-control form_input"
                        placeholder="分機"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-xl-4">
                    <div class="form-group d-flex align-items-center">
                      <label>縣市</label>
                      <select
                        id="addrCountyName"
                        v-model="warehouse_detail.addrCountyName"
                        v-validate="'required'"
                        name="縣市"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.縣市')}"
                        data-vv-scope="detail"
                        @change="filter_district()"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          縣市
                        </option>
                        <option
                          v-for="item in option.address"
                          :key="item.addrCountyCode"
                          :value="item.addrCountyName"
                        >
                          {{ item.addrCountyName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="form-group d-flex align-items-center">
                      <label>行政區</label>
                      <select
                        id="addrCityName"
                        v-model="warehouse_detail.addrCityName"
                        v-validate="'required'"
                        name="行政區"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.行政區')}"
                        data-vv-scope="detail"
                        @change="get_city_code()"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          行政區
                        </option>
                        <option
                          v-for="item in district_data"
                          :key="item.addrCityCode"
                          :value="item.addrCityName"
                        >
                          {{ item.addrCityName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label>地址</label>
                  <input
                    id="addrDetail"
                    v-model="warehouse_detail.addrDetail"
                    v-validate="'required'"
                    type="text"
                    name="詳細地址"
                    class="form-control form_input flex-fill"
                    :class="{'is-invalid': errors.has('detail.詳細地址')}"
                    data-vv-scope="detail"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label>黑貓收費優先級</label>
                  <input
                    id="blackCatPriority"
                    v-model="warehouse_detail.blackCatPriority"
                    v-validate="'required'"
                    type="text"
                    name="黑貓收費優先級"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('detail.黑貓收費優先級')}"
                    data-vv-scope="detail"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <a
                    href="#"
                    class="btn btn_primary rounded-0"
                    @click.prevent="edit_warehouse(edit_section_new)"
                  >{{ edit_section_new === true ? '建立' : '更新' }}</a>
                  <a
                    href="#"
                    class="btn btn_default rounded-0"
                    @click.prevent="init_warehouse_detail(), init_validate(), edit_mode = false, edit_section_new = false, edit_section_content = ''"
                  >關閉</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="nav_tab === 'warehouse_detail'"
          class="search_section"
        >
          <div class="form-group d-flex align-items-center">
            <label for="main_category">主分類品項</label>
            <select
              id="main_category"
              v-model="search.main_category"
              v-validate="'required'"
              name="主分類品項"
              class="form-control"
              :class="{'is-invalid': errors.has('search.主分類品項')}"
              data-vv-scope="search"
              @change="get_sub_category()"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                主分類品項
              </option>
              <option
                v-for="item in category_list"
                :key="item.categoryCode"
                :value="item.categoryCode"
              >
                {{ item.categoryName }}
              </option>
            </select>
          </div>
          <div class="form-group d-flex align-items-center">
            <label for="sub_category">次分類品項</label>
            <select
              id="sub_category"
              v-model="search.sub_category"
              v-validate="'required'"
              name="次分類品項"
              class="form-control"
              :class="{'is-invalid': errors.has('search.次分類品項')}"
              data-vv-scope="search"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                次分類品項
              </option>
              <option
                v-for="item in subcategory_list"
                :key="item.categorySubCode"
                :value="item.categorySubCode"
              >
                {{ item.categorySubName }}
              </option>
            </select>
          </div>
          <a
            href="#"
            class="search_btn d-flex align-items-center justify-content-center"
            @click.prevent="get_storage_list()"
          >
            <span>查詢</span>
          </a>
        </div>
        <div
          v-if="nav_tab === 'warehouse_detail'"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  商品名稱/規格
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  售價/單位
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  銷售/庫存/可購買
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  各倉庫存
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  顯示
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="storage in storage_list"
                :key="storage.prodSerial"
                :class="{ 'table_disabled': storage.statusDesc !== '' ? true : false }"
              >
                <td>
                  <div>{{ storage.prodName }}{{ storage.standard }}</div>
                  <div class="text-color_primary">
                    {{ storage.prodSerial }}
                  </div>
                </td>
                <td class="">
                  {{ storage.price }}/{{ storage.unit }}
                </td>
                <td class="">
                  {{ storage.storageQuantitySales }}/{{ storage.storageQuantity }}/{{ storage.storageQuantityMax === 99999 ? '∞' : storage.storageQuantityMax }}
                </td>
                <td class="">
                  <div v-html="storage.storageQuantityDepot" />
                </td>
                <td class="">
                  <div>缺貨-{{ storage.optionOutOfStock === true ? '顯示' : '不顯示' }}</div>
                  <div :class="{ 'text-success': storage.optionShowFront, 'text-danger': !storage.optionShowFront }">
                    前台-{{ storage.optionShowFront === true ? '顯示' : '不顯示' }}
                  </div>
                  <div>庫存計算-{{ storage.optionStorageAdj === false ? '不限' : '正常' }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_WAREHOUSE_LIST,
  API_GET_WAREHOUSE_DETAIL,
  API_ADD_WAREHOUSE,
  API_UPDATE_WAREHOUSE,
  API_DELETE_WAREHOUSE,
  API_GET_WAREHOUSE_CATEGORY_LIST,
  API_GET_STORAGE_LIST,
  API_UPDATE_STORAGE,
  API_GET_PRODUCT_BOM,
  API_UPDATE_PRODUCT_BOM,
} from '@/plugins/api';

export default {
  data() {
    return {
      now_page: 'stock',
      warehouse_list: [], /* 倉庫列表 */
      category_list: [], /* 商品分類列表 */
      subcategory_list: [], /* 次分類列表 */
      nav_tab: 'warehouse_list',
      warehouse_detail: {}, /* 倉庫資料 */
      edit_mode: false, /* 編輯模式 */
      edit_section_content: '', /* 編輯區塊內容 ex: warehouse */
      edit_section_new: false, /* 編輯的區塊是否為新 */
      district_data: [], /* 行政區資料 */
      search: {
        warehouse: '',
        main_category: '',
        sub_category: '',
      },
      storage_list: [], /* 庫存資料列表 */
    };
  },
  computed: {
    ...mapGetters('system', ['option']),
    warehouse_system() {
      const vm = this;
      const data = vm.option.system.slice();
      data.forEach((item) => {
        item.enable = false;
      });
      return data;
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system_option();
    await vm.get_warehouse_list();
    await vm.get_category_list();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system_option']),
    /* 初始化 warehouse_detail */
    async init_warehouse_detail() {
      const vm = this;
      vm.warehouse_detail = {};
    },
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('search');
        vm.$validator.errors.clear('detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 取得行政區 */
    async filter_district() {
      const vm = this;
      vm.option.address.forEach((item) => {
        if (item.addrCountyName === vm.warehouse_detail.addrCountyName) {
          vm.district_data = item.cities;
        }
      });
      await vm.get_city_code();
    },
    /* 取得郵遞區號 */
    async get_city_code() {
      const vm = this;
      if (vm.district_data.length !== 0) {
        vm.district_data.forEach((item) => {
          if (item.addrCityName === vm.warehouse_detail.addrCityName) {
            vm.warehouse_detail.addrCityName = item.addrCityName;
            vm.warehouse_detail.addrCityCode = item.addrCityCode;
          }
        });
      }
    },
    /* 取得倉庫列表 */
    async get_warehouse_list() {
      const vm = this;
      const response = await API_GET_WAREHOUSE_LIST();
      const res = response.data;
      if (res.code === '0000') {
        vm.warehouse_list = res.data;
      }
    },
    /* 取得商品分類列表 */
    async get_category_list() {
      const vm = this;
      const response = await API_GET_WAREHOUSE_CATEGORY_LIST();
      const res = response.data;
      if (res.code === '0000') {
        vm.category_list = res.data;
      }
    },
    /* 篩選次分類列表 */
    async get_sub_category() {
      const vm = this;
      vm.category_list.forEach((item) => {
        if (item.categoryCode === vm.search.main_category) {
          vm.subcategory_list = item.items;
        }
      });
    },
    /* 取得倉庫資料 */
    async get_warehouse(depotCode, isnew) {
      const vm = this;
      const response = await API_GET_WAREHOUSE_DETAIL(depotCode);
      const res = response.data;
      if (res.code === '0000') {
        vm.warehouse_detail = res.data;
        vm.edit_mode = true;
        vm.edit_section_content = 'warehouse';
        vm.edit_section_new = isnew;
        await vm.filter_district();
      }
    },
    /* 建立/更新 倉庫資料 */
    async edit_warehouse(isnew) {
      const vm = this;
      switch (isnew) {
        case true:
          await vm.create_warehouse();
          break;
        case false:
          await vm.update_warehouse();
          break;
        default:
          break;
      }
    },
    async create_warehouse() {
      const vm = this;
      // console.log('create_warehouse');
    },
    async update_warehouse() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        const systemCodelist = ['GP'];
        const data = {
          status: vm.warehouse_detail.status,
          depotCode: vm.warehouse_detail.depotCode,
          depotName: vm.warehouse_detail.depotName,
          fullname: vm.warehouse_detail.fullname,
          company: vm.warehouse_detail.company,
          celnum: vm.warehouse_detail.celnum,
          telnum: vm.warehouse_detail.telnum,
          telnumExt: vm.warehouse_detail.telnumExt,
          addrCityCode: vm.warehouse_detail.addrCityCode,
          addrDetail: vm.warehouse_detail.addrDetail,
          systemCode: systemCodelist,
          blackCatPriority: parseInt(vm.warehouse_detail.blackCatPriority, 10),
        };
        const response = await API_UPDATE_WAREHOUSE(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.edit_mode = false;
          vm.edit_section_content = '';
          vm.edit_section_new = false;
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.init_warehouse_detail();
          await vm.get_warehouse_list();
          await vm.init_validate();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 刪除倉庫資料 */
    async delete_warehouse(depotCode) {
      const vm = this;
      const value = await vm.$swal({
        title: '確定刪除此倉庫?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '刪除',
      });
      if (value.isConfirmed) {
        const response = await API_DELETE_WAREHOUSE(depotCode);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_warehouse_list();
        }
      }
    },
    /* 取得庫存列表 */
    async get_storage_list() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('search');
      if (validateResult) {
        const data = {
          depotCode: vm.search.warehouse,
          categorySubCode: vm.search.sub_category,
        };
        const response = await API_GET_STORAGE_LIST(vm.search.sub_category);
        const res = response.data;
        if (res.code === '0000') {
          vm.storage_list = res.data;
        }
      }
    },
  },
};
</script>
